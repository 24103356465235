const LinkedinSvg = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_80_277)'>
        <path
          d='M35.3438 0.914051H3.65625C2.93728 0.906734 2.24478 1.18493 1.73068 1.6876C1.21659 2.19027 0.922902 2.87635 0.914062 3.5953V35.4108C0.9245 36.1287 1.21889 36.8132 1.73281 37.3145C2.24673 37.8159 2.93832 38.0933 3.65625 38.0859H35.3438C36.0628 38.0916 36.7548 37.8125 37.2686 37.3095C37.7825 36.8065 38.0763 36.1206 38.0859 35.4016V3.58616C38.0731 2.86932 37.7779 2.18651 37.2644 1.68615C36.7509 1.18578 36.0607 0.908316 35.3438 0.914051Z'
          fill='#0076B2'
        />
        <path
          d='M6.41678 14.8474H11.9347V32.6016H6.41678V14.8474ZM9.17725 6.01147C9.81012 6.01147 10.4288 6.19918 10.955 6.55085C11.4811 6.90252 11.8912 7.40235 12.1332 7.98711C12.3753 8.57187 12.4385 9.21528 12.3148 9.83595C12.1911 10.4566 11.8861 11.0267 11.4384 11.474C10.9906 11.9213 10.4203 12.2257 9.79951 12.3488C9.17873 12.4719 8.53537 12.4081 7.95085 12.1655C7.36632 11.9229 6.86688 11.5124 6.51571 10.9859C6.16454 10.4594 5.97743 9.84052 5.97803 9.20765C5.97884 8.35969 6.31625 7.54674 6.91613 6.94743C7.51602 6.34812 8.32929 6.01147 9.17725 6.01147ZM15.3959 14.8474H20.6853V17.2849H20.7584C21.4958 15.8894 23.2934 14.4178 25.9777 14.4178C31.5657 14.4056 32.6016 18.0832 32.6016 22.8516V32.6016H27.0837V23.9637C27.0837 21.907 27.0472 19.2593 24.2166 19.2593C21.3861 19.2593 20.9047 21.5018 20.9047 23.8296V32.6016H15.3959V14.8474Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_80_277'>
          <rect width='39' height='39' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default LinkedinSvg
