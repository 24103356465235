const FacebookSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6304 1.4339H3.37243C2.30178 1.4339 1.43384 2.30184 1.43384 3.37249V34.6305C1.43384 35.7011 2.30178 36.5691 3.37243 36.5691H34.6304C35.7011 36.5691 36.569 35.7011 36.569 34.6305V3.37249C36.569 2.30184 35.7011 1.4339 34.6304 1.4339Z"
        fill="#2F2B43"
        fillOpacity="0.6"
      />
      <path
        d="M25.6738 36.5661V22.9603H30.2397L30.9225 17.6581H25.6738V14.2737C25.6738 12.7389 26.1013 11.6909 28.3011 11.6909H31.1096V6.94093C29.7496 6.7992 28.3829 6.7318 27.0156 6.73905C22.9722 6.73905 20.1875 9.20312 20.1875 13.7483V17.6581H15.6216V22.9603H20.1875V36.5661H25.6738Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookSvg;
