import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/svg/Logo";
import YoutubeIcon from "../../../assets/svg/YoutubeIcon";
import LinkedinIcon from "../../../assets/svg/LinkedinIcon";
import InstagramIcon from "../../../assets/svg/FacebookSvg";
import "./styles.scss";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setEmail(value);

    if (emailRegex.test(value)) {
      setError("");
      return;
    }
    if (!value) {
      setError("");
      return;
    }
    setError("Invalid email address");
  };

  const authenticate = async () => {
    try {
      const response = await axios({
        url: "https://api.podio.com/oauth/token/v2",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          grant_type: "password",
          username: process.env.REACT_APP_PODIO_ADMIN_USERNAME,
          password: process.env.REACT_APP_PODIO_ADMIN_PASSWORD,
          client_id: process.env.REACT_APP_NEWS_LETTER_APP_CLIENT_ID,
          redirect_uri: process.env.REACT_APP_PODIO_REDIRECT_URI,
          client_secret: process.env.REACT_APP_NEWS_LETTER_APP_CLIENT_SECRET,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("Error", error.response.data);
      return;
    }
  };

  const storeDataInPodio = async (accessToken) => {
    try {
      const appId = process.env.REACT_APP_NEWS_LETTER_APP_ID;
      const url = `https://api.podio.com/item/app/${appId}/`;
      const payload = {
        fields: {
          title: "Subscribed User",
          email: [{ type: "work", value: email }],
        },
      };

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `OAuth2 ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error storing data in Podio:", error);
      return;
    }
  };

  const errorToast = () => {
    return notification.error({
      message: "Error",
      description:
        "There was an issue sending your data. Please try again later.",
    });
  };

  const onSubmit = async () => {
    if (!email) return;
    if (!emailRegex.test(email)) return;

    try {
      setLoading(true);
      const authorization = await authenticate();
      if (!authorization) {
        setLoading(false);
        setEmail("");
        errorToast();
        return;
      }
      const data = await storeDataInPodio(authorization?.access_token);
      if (!data) {
        setLoading(false);
        setEmail("");
        errorToast();
        return;
      }
      setEmail("");
      setLoading(false);
      notification.success({
        message: "Success",
        description:
          "You have successfully subscribed to our newsletter. Thank you!",
      });
    } catch (error) {
      setLoading(false);
      setEmail("");
      console.log("Error", error);
      errorToast();
    }
  };

  const getPageData = async () => {
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fundheaders/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            links: {
              populate: ["title"],
            },
          },
        },
      };

      const res = await axios.request(config);
      if (res?.data?.data?.id && res?.data?.data?.attributes) {
        setPageData(res.data.data.attributes);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div className="footer">
      <div className="bg-[#1D2939]">
        <div className="py-20 flex flex-col gap-5 md:flex-row justify-between items-center md:items-start w-full md:w-5/6 md:mx-auto ">
          <div className="w-full px-5 md:w-[50%]">
            <h1 className="text-white font-semibold text-[20px]">
              Subscribe to our newsletter
            </h1>
            <p className="text-[#ffffff99] font-normal text-[16px] mt-[8px]">
              Get a summary of what we’ve shipped during the last month, behind
              the scenes updates, and team picks.
            </p>
          </div>
          <div className="w-full px-5 md:flex md:flex-col md:justify-end md:items-end ">
            <div className="flex flex-col md:flex-row gap-4">
              <div>
                <Input
                  type="text"
                  placeholder="Enter your email"
                  className="w-full md:w-[300px] h-[40px]"
                  value={email}
                  onChange={onChangeHandler}
                />
                {error && (
                  <div className="text-xs text-red-500 mt-1">{error}</div>
                )}
              </div>
              <Button
                type="primary"
                onClick={onSubmit}
                className="w-full md:w-[100] h-[40px]"
                loading={loading}
              >
                {"Subscribe"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full md:w-5/6 mx-auto flex flex-col gap-8 md:gap-0 md:flex-row justify-between items-center px-5 md:px-0 py-10 md:py-0">
          <div className="w-full flex justify-start items-end">
            <span
              className="flex justify-start items-end cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <Logo />
              <span className="ml-1 font-bold text-lg sm:text-xl md:text-2xl text-[#1D2939]">
                Blu Hat Funding
              </span>
            </span>
          </div>
          <div className="w-full flex flex-col gap-8 md:gap-0 md:flex-row md:items-center">
            <div className="flex py-6 md:py-[48px]">
              <div className="mr-[24px] flex flex-col gap-3 md:gap-0 md:flex-row ">
                {pageData?.links?.length > 0 &&
                  pageData.links.map((link) => (
                    <Link
                      className="mr-[16px] font-medium text-[16px] text-[#2f2b4399]"
                      to={link.path}
                    >
                      {link.title}
                    </Link>
                  ))}
              </div>
            </div>
            <div class="w-full border-b border-[#2F2B4399] opacity-60 md:hidden"></div>
            <div className="flex flex-col gap-4">
            <div className="w-full flex gap-5">
  <a href="https://www.youtube.com/@bluhatbookkeeping" target="_blank" rel="noopener noreferrer" className="">
    <YoutubeIcon />
  </a>
  <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFxNTZdzX2JoAAAAZM4wkjINTGIsBRlxFT5vt5zBacUWUKqqs1O8RUbSoyqJ3tEshBEkl_Tkpjc8gU2hSsLRN038HKMLO9fvLctHqSMUTVN5qaoFN_BbeEiUC8RHgrRWmomjpo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fbluhatbookkeeping%2F" target="_blank" rel="noopener noreferrer" className="">
    <LinkedinIcon />
  </a>
  <a href="https://www.facebook.com/people/Blu-Hat-Bookkeeping/61564529010630/" target="_blank" rel="noopener noreferrer">
    <InstagramIcon />
  </a>
</div>

              <div className="md:hidden text-[#2F2B4399] text-[16px]">
                &copy; 2023, All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
