import React from 'react';

const ArrowRight=()=>{
  return (
    <svg
      width='7'
      height='10'
      fill='none'
      viewBox='0 0 7 10'
    >
      <path
        stroke='#2F2B43'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M1.333 9.167L5.5 5 1.333.833'
      ></path>
    </svg>
  );
}

export default ArrowRight;
