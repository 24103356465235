import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PageLoader from "../../components/PageLoader";
import "./styles.scss";

const OurStory = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);

  // Methods
  const getPageData = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/our-stories/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            contactButton: {
              populate: ["title", "link"],
            },
            statImage: {
              populate: ["image"],
            },
            stats: {
              populate: ["title", "description"],
            },
          },
        },
      });

      if (
        response.status === 200 &&
        response?.data?.data?.attributes?.publishedAt != null
      ) {
        setPageData(response?.data?.data?.attributes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error:~", error);
      return;
    }
  };

  const getCroppedImageStyle = (imageSrc) => {
    const aspectRatio = 1 / 1.1;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = 560 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
    };
  };
  const getCroppedImageStyleMobile = (imageSrc) => {
    const aspectRatio = 1 / 1.1;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = 300 / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
    };
  };

  const buttonOnClickHandler = () => navigate(pageData?.contactButton?.link);

  // Effects
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageData();
  }, []);

  if (loading) return <PageLoader />;

  return (
    <div className="w-full our-story">
      <div className="w-full bg-[#fcfcfd]">
        <div className="w-5/6 mx-auto py-[45px] md:py-[96px]">
          {pageData?.title && (
            <h1 className="mb-[12px] text-center text-[#7f56d9] font-semibold text-[16px]">
              {pageData.title}
            </h1>
          )}
          {pageData?.text && (
            <h1 className="mb-[24px] text-center font-semibold text-[30px] md:text-[48px]">
              {pageData.text}
            </h1>
          )}
          {pageData?.subText && (
            <p className="w-full md:w-[50%] text-center mx-auto text-[#475467] font-normal text-[18px] md:text-[20px]">
              {pageData?.subText}
            </p>
          )}
        </div>
      </div>
      <div className="w-full bg-[white]">
        <div className="w-5/6 mx-auto py-[40px] md:py-[80px] flex flex-col gap-10">
          <div>
            {pageData?.paraTitle && (
              <h1 className="mb-[12px] test-start text-[#6941c6] font-semibold text-[14px] md:text-[16px]">
                {pageData.paraTitle}
              </h1>
            )}
            {pageData?.paraText && (
              <h1 className="mb-[20px] text-start font-semibold text-[30px] md:text-[36px]">
                {pageData.paraText}
              </h1>
            )}
            {pageData?.paraSubText && (
              <p className="text-start mx-auto text-[#475467] font-normal text-[18px] md:text-[20px]">
                {pageData.paraSubText}
              </p>
            )}
          </div>
          <div className="flex flex-col md:flex-row justify-between w-full">
            {pageData?.paraOne && (
              <div className="w-full md:w-[46%]">
                <p className="text-[#475467] font-normal text-[16px] md:text-[18px] markdown">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {pageData.paraOne}
                  </ReactMarkdown>
                </p>
              </div>
            )}
            {pageData?.paraTwo && (
              <div className="w-full md:w-[46%]">
                <p className="text-[#475467] font-normal text-[16px] md:text-[18px] markdown">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {pageData?.paraTwo}
                  </ReactMarkdown>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-[#fcfcfd]">
        {(pageData?.statImage?.data?.attributes?.url ||
          pageData?.stats?.length > 0) && (
          <div className="w-5/6 mx-auto py-[96px] flex flex-col-reverse md:flex-row justify-between">
            {pageData?.statImage?.data?.attributes?.url && (
              <>
                <div className="w-full md:w-[50%] hidden md:block">
                  <div
                    style={getCroppedImageStyle(
                      `${pageData.statImage.data.attributes.url}`
                    )}
                  ></div>
                </div>
                <div className="w-full md:w-[50%] md:hidden block">
                  <div
                    style={getCroppedImageStyleMobile(
                      `${pageData.statImage.data.attributes.url}`
                    )}
                  ></div>
                </div>
              </>
            )}
            {pageData?.stats?.length > 0 && (
              <div className="w-full md:w-[50%] flex flex-col justify-center md:pl-[96px]">
                {pageData?.statTitle && (
                  <h1 className="text-[#6941c6] font-semibold text-[16px]">
                    {pageData.statTitle}
                  </h1>
                )}
                {pageData?.statText && (
                  <h1 className="text-[black] font-semibold text-[30px] md:text-[48px]">
                    {pageData?.statText}
                  </h1>
                )}
                <div className="flex flex-col md:flex-row justify-between flex-wrap mt-[64px]">
                  {pageData?.stats?.map((item) => {
                    return (
                      <div
                        className="w-[46%] flex flex-col justify-start mb-[24px] md:mb-[48px]"
                        key={item.title}
                      >
                        <h1 className="text-[#7f56d9] font-semibold text-[43px] md:text-[58px]">
                          {item.title}
                        </h1>
                        <h1 className="text-[black] font-semibold text-[16px] md:text-[18px] mt-[6px]">
                          {item.description}
                        </h1>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full bg-[white]">
        {(pageData?.endText ||
          pageData?.endSubText ||
          pageData?.contactButton?.id) && (
          <div className="w-5/6 mx-auto py-[96px] flex flex-col justify-center">
            {pageData?.endText && (
              <h1 className="text-[36px] font-semibold mb-[20px] text-center">
                {pageData?.endText}
              </h1>
            )}
            {pageData?.endSubText && (
              <p className="font-normal text-[20px] text-[#475467] w-full mb-[40px] text-center">
                {pageData?.endSubText}
              </p>
            )}
            {pageData?.contactButton?.id && (
              <button
                className="bg-[#3364f6] w-full md:w-[150px]  mx-auto font-medium text-[16px] text-[#ffffff] text-center rounded-[12px] py-[12px] px-[16px]"
                onClick={buttonOnClickHandler}
              >
                {pageData?.contactButton?.title}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default OurStory;
