import axios from "axios";
import { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import BlogsCarasoul from "../../components/BlogsCarasoul";
import PageLoader from "../../components/PageLoader";

const Articles = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const getPageData = async () => {
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-articles/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (
        res?.data?.data?.id &&
        res?.data?.data?.attributes?.publisedAt != null
      ) {
        setPageData(res.data.data.attributes);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  const getBlogs = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-articles?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        setBlogs(res.data.data);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  const getCategories = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_STRAPI_URL}/api/article-categories`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            "fund_articles": {
              populate: ["title"],
            },
          },
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        let tempCtg = [];
        res.data.data.map((item) => {
          console.log('tempCtg',item)
          if (item?.attributes?.fund_articles?.data?.length > 0) {
            tempCtg.push(item);
          }
        });
        setCategories(tempCtg);
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    return true;
  };
  useEffect(()=>{
    console.log('categories',categories)
  },[categories])
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getBlogs();
      await getCategories();
      await getPageData();
      setLoading(false);
    };

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchData();
  }, []);
  const navigateToCategory = (slug) => {
    navigate(`/article/category/${slug}`);
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="w-5/6 mx-auto blogs">
          <div>{/* <h1>Search Bar</h1> */}</div>
          <div className="mt-0 lg:mt-[30px] flex flex-col w-full mx-auto justify-center">
            {pageData?.title && (
              <h1 className="text-center font-bold text-[#1D2939] text-2xl lg:text-5xl">
                {pageData?.title}
              </h1>
            )}
            {pageData?.description && (
              <p className="text-center font-normal text-sm lg:text-xl mt-[8px] lg:mt-[10px] text-[#475467]">
                {pageData?.description}
              </p>
            )}
          </div>
          {blogs?.length > 0 && (
            <>
              <BlogsCarasoul
                carouselItems={blogs}
                navigateToCategory={navigateToCategory}
              />
            </>
          )}
          {categories?.length > 0 && (
            <>
              <div className="my-[25px] lg:my-[50px] ">
                <h1 className="text-center font-semibold text-xl lg:text-3xl mb-[10px] lg:mb-[20px]">
                  Explore More Topics
                </h1>
                <div className="flex flex-wrap">
                  {categories?.length > 0 &&
                    categories?.map((category) => {
                      return (
                        <span
                          onClick={() =>
                            navigateToCategory(category.attributes.slug)
                          }
                          className="cursor-pointer font-medium lg:font-semibold text-base text-[#475467] rounded-md lg:rounded-xl px-2 lg:px-4 py-1 lg:py-3 mr-3 lg:mr-5 my-1 lg:my-2"
                          style={{ border: "2px solid #1D2939" }}
                          key={`category-${category?.attributes?.name}`}
                        >
                          {category?.attributes?.name}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Articles;
