import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import OurStory from "./pages/OurStory";
import Services from "./pages/Services";
import PageNotFound from "./pages/PageNotFound";
import Privacy from "./pages/Privacy";
import ContactUs from "./pages/ContactUs";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Category from "./pages/Category";
import "./index.css";
import BookACall from "./pages/BookACall";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/services" element={<Services />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/book-a-call" element={<BookACall />} />
          <Route path="/article" element={<Articles />} />
          <Route path="/article/:slug" element={<Article />} />
          <Route path="/article/category/:slug" element={<Category />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
