const ArrowUp = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6662 9.51196L7.99955 2.8453L1.33288 9.51196L0.154371 8.33345L7.41029 1.07753C7.73573 0.752092 8.26337 0.752092 8.5888 1.07753L15.8447 8.33345L14.6662 9.51196Z"
        fill="#344054"
      />
    </svg>
  );
};

export default ArrowUp;
