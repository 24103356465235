import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PageLoader from "../../components/PageLoader";
import "./styles.scss";

const Services = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);

  // Methods
  const getCroppedImageStyle = (imageSrc, imgHeight) => {
    const aspectRatio = 1 / 1.1;
    const modifiedSrc = `${imageSrc}`;
    const width = "100%";
    const height = imgHeight / aspectRatio;
    return {
      backgroundImage: `url(${modifiedSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: `${width}%`,
      height: `${height}px`,
      borderRadius: "15px",
    };
  };

  const getPageData = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-services/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            button: {
              populate: ["title", "path"],
            },
            image: {
              populate: ["image"],
            },
            description: {
              populate: ["title", "description", "image"],
            },
            extraDescription: {
              populate: ["title", "description"],
            },
          },
        },
      });

      if (
        response.status === 200 &&
        response?.data?.data?.attributes?.publishedAt != null
      ) {
        setPageData(response?.data?.data?.attributes);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error:~", error);
      return;
    }
  };

  const buttonOnClickHandler = () => navigate(pageData?.button?.path);

  // Effects
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPageData();
  }, []);

  if (loading) return <PageLoader />;

  return (
    <div className="w-full services px-5 md:px-0">
      <div className="w-full">
        <div className="w-full md:w-5/6 mx-auto pt-[64px] pb-[96px]">
          <div className="flex flex-col md:flex-row gap-12 md:gap-0 justify-between items-center">
            <div className="w-full md:w-[50%] flex flex-col justify-start">
              <h1 className="text-[40px] md:text-[52px] font-semibold pb-[15px] md:pb-[24px]">
                {pageData?.title}
              </h1>
              <p className="w-full md:w-[80%] text-[18px] md:text-[20px] text-[#475467] font-normal pb-[15px]">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {pageData?.text}
                </ReactMarkdown>
              </p>

              <button
                className="bg-[#3364f6] w-full md:w-[150px] font-medium text-[16px] text-[#ffffff] text-center rounded-[12px] py-[12px] px-[16px] mt-[20px] md:mt-[48px]"
                onClick={buttonOnClickHandler}
              >
                {pageData?.button?.title}
              </button>
            </div>
            <div className="w-full md:w-[50%] hidden md:block">
              <div
                style={getCroppedImageStyle(
                  `${pageData?.image?.data?.attributes?.url}`,
                  340
                )}
              ></div>
            </div>
            <div className="w-full md:w-[50%] md:hidden block">
              <div
                style={getCroppedImageStyle(
                  `${pageData?.image?.data?.attributes?.url}`,
                  340
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        {pageData?.description?.length > 0 &&
          pageData?.description?.map((item, index) => (
            <div className="w-full md:w-[720px] px-0 mx-auto pt-[35px] md:pt-[77px]">
              <h1 className="font-semibold text-[30px] mb-[20px]">
                {item?.title}
              </h1>
              <p className="markdown font-normal text-[18px] text-[#475467]">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {item?.description}
                </ReactMarkdown>
              </p>
              <div className="w-full mt-[35px]">
                {item?.image?.data && (
                  <div
                    style={getCroppedImageStyle(
                      `${item?.image?.data?.attributes?.url}`,
                      380
                    )}
                  ></div>
                )}
              </div>
            </div>
          ))}
        <div className="w-full md:w-[720px] px-0 mx-auto pt-[77px]">
          <div className="flex flex-col items-start px-0 md:px-[32px] py-[32px] bg-[#f9fafb] mt-[40px]">
            <h1 className="font-semibold text-[30px] mb-[20px]">
              {pageData?.extraDescription?.title}
            </h1>
            <p className="font-normal text-[18px] text-[#475467] markdown mb-[20px]">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {pageData?.extraDescription?.description}
              </ReactMarkdown>
            </p>
          </div>
        </div>
      </div>
      <div className="w-5/6 mx-auto pt-10 pb-20 flex justify-between">
        <button
          className="bg-[#3364f6] w-full md:w-[150px] mx-auto font-medium text-[16px] text-[#ffffff] text-center rounded-[12px] py-[12px] px-[16px]"
          onClick={buttonOnClickHandler}
        >
          {pageData?.button?.title}
        </button>
      </div>
    </div>
  );
};
export default Services;
