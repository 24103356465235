import axios from "axios";
import { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import BlogCard from "../../components/BlogCard";
import Spinner from "../../assets/svg/Spinner";

const Category = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const getBlogsByCategory = async (slug, page) => {
    setLoading(true);
    try {
      let config = {
        method: "get",

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-articles`,
        params: {
          pagination: {
            pageSize: 9,
            page: page,
          },
          filters: {
            article_category: {
              slug: {
                $in: slug,
              },
            },
          },
          populate: {
            cover: {
              fields: ["name", "url"],
            },
            author: {
              populate: ["avatar"],
            },
            article_category: {
              populate: ["name"],
            },
          },
        },
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        if (blogs?.length) {
          setBlogs((prev) => [...prev, ...res.data.data]);
        } else {
          setBlogs(res.data.data);
        }
        if (
          res?.data?.meta?.pagination?.pageCount &&
          page < res?.data?.meta?.pagination?.pageCount
        ) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    setLoading(false);
    return true;
  };
  const getCategories = async (slug) => {
    setLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_STRAPI_URL}/api/article-categories`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            "fund_articles": {
              populate: ["title"],
            },
          },
        },
      };

      const res = await axios.request(config);
      if (res?.status == 200 && res?.data?.data?.length) {
        let tempCtg = [];
        res.data.data.map((item) => {
          if (item?.attributes?.fund_articles?.data?.length > 0) {
            tempCtg.push(item);
          }
        });
        setCategories(tempCtg);
        res?.data?.data?.forEach((item) => {
          if (item?.attributes?.slug == slug) {
            setCategory(item);
          }
        });
      }
    } catch (error) {
      console.log("error in fetch blogs", error);
    }
    setLoading(false);
    return true;
  };
  useEffect(() => {
    const fetchData = async (slug, page) => {
      await getBlogsByCategory(slug, page);
    };
    if (params?.slug && page && hasMore) {
      fetchData(params.slug, page);
    }
  }, [params, page]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const fetchData = async (slug) => {
      await getCategories(slug);
    };
    if (params?.slug) {
      fetchData(params.slug);
    }
  }, [params]);
  useEffect(() => {
    if (params?.slug) {
      if (!selectedCategory) {
        setSelectedCategory(params.slug);
      } else if (selectedCategory !== params.slug) {
        setBlogs([]);
        setHasMore(true);
        setSelectedCategory(params.slug);
        setPage(null);
      }
    }
  }, [params]);
  useEffect(() => {
    if (!page) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setPage(1);
    }
  }, [page]);
  const navigateToCategory = (slug) => {
    navigate(`/article/category/${slug}`);
  };
  return (
    <div className="w-5/6 mx-auto blogs" style={{ bordeer: "1px solid black" }}>
      <div>{/* <h1>Search Bar</h1> */}</div>
      <div className="mt-0 lg:mt-[30px] flex flex-col w-full mx-auto justify-center">
        {category?.attributes?.headline && (
          <h1 className="text-center font-bold text-2xl lg:text-5xl text-[#1D2939]">
            {category?.attributes?.headline}
          </h1>
        )}
        {category?.attributes?.subHeadline && (
          <p className="text-center font-normal text-lg lg:text-2xl text-[#475467]">
            {category?.attributes?.subHeadline}
          </p>
        )}
      </div>
      {blogs?.length > 0 && (
        <div className="mt-[20px] lg:mt-[40px]">
          <div className="flex justify-start items-start flex-wrap">
            {blogs.map((item, index, self) => {
              return (
                <BlogCard
                  item={item}
                  isLast={index === self.length - 1}
                  newLimit={() => setPage(page + 1)}
                  srNo={index + 1}
                  navigateToCategory={navigateToCategory}
                />
              );
            })}
          </div>
        </div>
      )}
      <div className="flex items-center justify-center mt-[15px] lg:mt-[30px]">
        {loading && (
          <div className="flex flex-col items-center justify-center">
            <Spinner />
            <span>...Loading</span>
          </div>
        )}
      </div>
      {categories?.length > 0 && (
        <div className="my-[25px] lg:my-[50px]">
          <h1 className="text-center font-semibold text-xl lg:text-3xl mb-[15px] lg:mb-[20px]">
            Explore More Topics
          </h1>
          <div className="flex flex-wrap">
            {categories?.map((category) => {
              return (
                <span
                  onClick={() => {
                    navigateToCategory(category?.attributes?.slug);
                  }}
                  className={`${
                    params?.slug == category?.attributes?.slug
                      ? "bg-black text-white"
                      : "text-[#475467]"
                  } cursor-pointer font-medium lg:font-semibold text-base rounded-md lg:rounded-xl px-2 lg:px-4 py-1 lg:py-3 mr-3 lg:mr-5 my-1 lg:my-2`}
                  style={{ border: "2px solid #1D2939" }}
                  key={`category-${category?.attributes?.name}`}
                >
                  {category?.attributes?.name}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default Category;
