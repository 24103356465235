import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import "../../index.css";

const Privacy = () => {
  const [privacyData, setPrivacyData] = useState({});
  const [faqsData, setFaqsData] = useState([]);

  // Methods
  const getPrivacyContent = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-privacys/1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      });

      if (
        response.status === 200 &&
        response?.data?.data?.attributes?.publishedAt != null
      ) {
        setPrivacyData(response?.data?.data?.attributes);
      }
    } catch (error) {
      console.log("Error:~", error);
      return;
    }
  };

  const getFAQsContent = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_STRAPI_URL}/api/fund-faqs`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setFaqsData(response?.data?.data);
      }
    } catch (error) {
      console.log("Error:~", error);
      return;
    }
  };

  // Effects

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getPrivacyContent();
    getFAQsContent();
  }, []);

  return (
    <>
      <div className="w-full bg-[#FCFCFD]">
        <div className="w-full md:w-5/6 mx-auto py-[96px] flex flex-col items-center">
          {privacyData?.subText && (
            <h1 className="mb-[12px] text-[#7f56d9] text-[16px] font-semibold text-center">
              {privacyData?.subText}
            </h1>
          )}
          {privacyData?.title && (
            <h1 className="mb-[24px] text-[42px] font-semibold text--">
              {privacyData?.title}
            </h1>
          )}
          {privacyData?.text && (
            <p className="w-5/6 text-[#475467] text-[16px] md:text-[20px] font-normal px-5 mx-auto text-center">
              {privacyData?.text}
            </p>
          )}
        </div>
      </div>
      <div className="w-full md:w-4/6 mx-auto px-5 md:px-0 py-0 md:py-16 flex flex-col gap-12">
        {privacyData?.description && (
          <div className="markdown text-[16px] md:text-[18px] text-[#475467]">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {privacyData?.description}
            </ReactMarkdown>
          </div>
        )}
        {faqsData?.length > 0 &&
          faqsData?.map((item, index) => (
            <div key={index} className="flex flex-col md:gap-8">
              {item?.attributes?.question && (
                <div className="text-[#101828] text-[25px] md:text-[30px] font-bold">
                  {item?.attributes?.question}
                </div>
              )}
              {item?.attributes?.Answer && (
                <div className="markdown text-[16px] md:text-[18px] text-[#475467]">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {item?.attributes?.Answer}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          ))}
      </div>
    </>
  );
};
export default Privacy;
