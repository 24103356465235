import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = ({ value, onChange }) => {
  return (
    <PhoneInput
      country="us"
      placeholder="+1 (555) 000-0000"
      value={value}
      onChange={onChange}
      enableSearch={true}
      inputClass="!w-full"
      buttonClass="!border-r-0 !bg-transparent "
    />
  );
};
export default PhoneNumberInput;
