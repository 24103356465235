import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
const aspectRatio = 16 / 9;
const getCroppedImageStyle = (imageSrc) => {
  const modifiedSrc = `${imageSrc}`;
  const width = 100;
  const height = 400 / aspectRatio;
  return {
    backgroundImage: `url(${modifiedSrc})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: `${width}%`,
    height: `${height}px`,
  };
};
const CarouselItem = ({ item, navigateToCategory, navigateToBlog }) => {
  return (
    <div
      className="w-full h-full p-[10px] lg:p-[20px] bg-white card-shadow rounded-xl"
      key={item?.attributes?.slug}
      style={{ bordeer: "1px solid red" }}
    >
      <div className="w-full">
        <div
          className="flex mx-auto"
          style={getCroppedImageStyle(
            !item.attributes?.cover?.data
              ? `${process.env.PUBLIC_URL}/dummyImage.jpg`
              : item.attributes?.cover?.data?.attributes?.url
          )}
        ></div>
      </div>
      <div className="max-h-[32px] lg:max-h-[46px] h-[32px] lg:h-[46px] mt-[8px] lg:mt-[10px] flex justify-start items-center flex-wrap overflow-hidden">
        {item?.attributes?.categories?.data?.length > 0 &&
          item.attributes.categories.data?.map((category) => {
            return (
              <span
                key={category?.attributes?.slug}
                className="bg-black text-white px-2 lg:px-3 py-1 lg:py-2 rounded-[5px] lg:rounded-[10px] mr-1 lg:mr-2 font-semibold text-base lg:text-lg cursor-pointer"
                onClick={() => navigateToCategory(category.attributes.slug)}
              >
                {category?.attributes?.name}
              </span>
            );
          })}
      </div>
      <div className="max-h-[48px] h-[48px] lg:max-h-[72px] lg:h-[72px] mt-[7px] lg:mt-[10px]">
        <h1 className="card-title font-semibold text-base lg:text-2xl text-[#101828]">
          {item?.attributes?.title}
        </h1>
      </div>

      <div className="flex items-center font-semibold text-xs lg:text-base text-[#3C87F1] lg:max-h-[23px] lg:h-[23px] mt-[7px] lg:mt-[10px]">
        {item?.attributes?.author?.data?.attributes?.name && (
          <>
            <span className="mr-1 lg:mr-2">By</span>{" "}
            <h1 className="author-title">
              {item?.attributes?.author?.data?.attributes?.name}
            </h1>
          </>
        )}
      </div>
      <div className="description max-h-[80px] h-[80px] lg:max-h-[97px] lg:h-[97px] mt-[7px] lg:mt-[10px]">
        {item?.attributes?.description && (
          <p className="font-semibold text-[#667085] text-sm lg:text-base">
            {item?.attributes?.description}
          </p>
        )}
      </div>
      <div className="flex justify-center mt-[7px] lg:mt-[10px]">
        <button
          onClick={() => {
            navigateToBlog(item?.attributes?.slug);
          }}
          className={`w-full bg-[#3364F6] rounded-[12px] py-[12px] px-[16px] text-[white] font-medium text-[16px] cursor-pointer`}
        >
          Read More
        </button>
      </div>
    </div>
  );
};
const BlogsCarasoul = ({ carouselItems, navigateToCategory }) => {
  const navigate = useNavigate();
  const navigateToBlog = (slug) => {
    navigate(`/article/${slug}`);
  };

  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="carousel-container mx-auto mt-[20px] rounded-xl"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass="m-0 p-2"
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {carouselItems.map((item, index) => (
          <CarouselItem
            item={item}
            navigateToCategory={navigateToCategory}
            navigateToBlog={navigateToBlog}
          />
        ))}
      </Carousel>
    </>
  );
};
export default BlogsCarasoul;
